import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const fetchInstitutionProfile = createAsyncThunk(
  "user/fetchInstitutionProfile",
  async () => {
    const response = await axiosInstance.get("/institution");
    return response.data;
  }
);

const institutionProfileSlice = createSlice({
  name: "institutionProfile",
  initialState: {
    institutionName: "",
    institutionCode: "",
    loadingInstitution: false,
    errorInstitution: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstitutionProfile.pending, (state) => {
        state.loadingInstitution = true;
        state.errorInstitution = null;
      })
      .addCase(fetchInstitutionProfile.fulfilled, (state, action) => {
        state.loadingInstitution = false;
        state.institutionName = action.payload.institutionName;
      })
      .addCase(fetchInstitutionProfile.rejected, (state, action) => {
        state.loadingInstitution = false;
        state.errorInstitution = action.error.message;
      });
  },
});

export default institutionProfileSlice.reducer;
