import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { Auth } from "aws-amplify";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleSignOut = async () => {
      await Auth.signOut();
    };

    // eslint-disable-next-line no-console
    handleSignOut()
      .then(() => {
        navigate("/sign-in");
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, []);

  return (
    <MDBox height="100vh" mx={2} mt={-3} p={2} mb={1} textAlign="center">
      <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
        <CircularProgress size={24} />
      </Grid>
    </MDBox>
  );
}
