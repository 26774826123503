import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { CircularProgress, Box, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ProgressPieChart({
  fontColor,
  color,
  icon,
  title,
  description,
  value,
  completeProcedure,
  total,
}) {
  return (
    <Card>
      <MDBox p={4} px={2} textAlign="center" lineHeight={1.25}>
        {/* <MDBox p={4} px={2} textAlign="center" lineHeight={1.25} bgColor="#f0f2f5"> */}
        <MDBox p={2} display="grid" justifyContent="center" alignItems="center" size={5}>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              thickness={3}
              size={80}
              variant="determinate"
              value={value}
              color={color}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                fontWeight={600}
                fontSize={18}
                variant="caption"
                component="div"
                color={fontColor}
              >
                {`${Math.round(value)}%`}
              </Typography>
            </Box>
          </Box>
        </MDBox>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>

        <Divider />

        <MDTypography variant="h5" fontWeight="medium">
          {`${completeProcedure} / ${total}`}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of PgyInfoCard
ProgressPieChart.defaultProps = {
  color: "info",
  fontColor: "info",
  value: "",
  completeProcedure: "",
  total: "",
  description: "",
};

// Typechecking props for the ProgressPieChart
ProgressPieChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  fontColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  completeProcedure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProgressPieChart;
