import { combineReducers } from "redux";
// Import your reducers here
import institutionProfileReducer from "./institutionProfileReducer";
import overviewScreenReducer from "./overviewScreenReducer";
import residentDetailReducer from "./residentDetailReducer";

const rootReducer = combineReducers({
  // Add your reducers here
  institutionProfile: institutionProfileReducer,
  overviewScreenData: overviewScreenReducer,
  residentDetail: residentDetailReducer,
});

export default rootReducer;
