/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React examples
// import ProgressPieChart from "examples/ProgressPieChart/progressPieChart";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProgressPieChart from "../../../customized-components/ProgressPieChart/progressPieChart";
import MDBox from "../../../components/MDBox";
import MDAvatar from "../../../components/MDAvatar";
import MDTypography from "../../../components/MDTypography";
import burceMars from "../../../assets/images/bruce-mars.jpg";

import {
  clearResidentData,
  fetchResidentDetailData,
} from "../../../store/reducers/residentDetailReducer";

function residentDetails() {
  const dispatch = useDispatch();
  const { id: residentId } = useParams();

  const [loading, setLoading] = useState(true);
  const [pgySortedResidentCards, setPgySortedResidentCards] = useState([]);

  const residentScores = useSelector((state) => state.residentDetail.procedureScores);
  const selectedResident = useSelector((state) => state.residentDetail.selectedResident);

  const renderList = () =>
    Object.entries(residentScores)
      .map(([pgy, pgyValue]) => {
        let procedureCount = Math.floor(
          (pgyValue.completed * 100) / pgyValue.mainCategoryMinimumScore
        );
        if (procedureCount > 100) {
          procedureCount = 100;
        }
        if (pgy !== "other") {
          return (
            <Grid
              item
              xs={12}
              md={6}
              xl={3}

              // key={pgy}
              // onClick={() => {
              //   navigate(`/resident/${resident.id}`, {
              //     state: { residentData: resident },
              //   });
              // }}
              // sx={{
              //   cursor: "pointer",
              //   transition: "0.3s",
              //   "&:hover": {
              //     transform: "scale(1.05)",
              //   },
              // }}
            >
              <ProgressPieChart
                color="primary"
                fontColor="primary"
                icon="person"
                title={pgy}
                completeProcedure={pgyValue.completed}
                total={pgyValue.mainCategoryMinimumScore}
                value={procedureCount}
              />
              {/* </ButtonBase> */}
            </Grid>
          );
        }
        return null;
      })
      .filter((item) => item !== null);

  /* ---------------------------Async--------------------------------------*/
  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      const { signal } = abortController;
      const body = {
        residentId,
      };

      setLoading(true); // Show loading state while fetching data

      try {
        await dispatch(fetchResidentDetailData({ body }));
      } catch (err) {
        if (!signal.aborted) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
        }
      }
      return () => {
        abortController.abort();
      };
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchData();
  }, [dispatch]);

  /* ---------------Render---------------*/

  useEffect(() => {
    if (residentScores) {
      setPgySortedResidentCards(renderList());
    }
  }, [residentScores]);

  /* -------------Dismount-----------------*/

  useEffect(
    () => () => {
      dispatch(clearResidentData());
    },
    [dispatch]
  );

  /*-----------------------------------------------------------------*/

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox height="100vh" mx={2} mt={-3} p={2} mb={1} textAlign="center">
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={70} />
          </Grid>
        </MDBox>
      ) : (
        <Card
          sx={{
            py: 2,
            px: 2,
            bgcolor: "#dee2e6",
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar alt="profile-image" size="xl" shadow="sm" bgColor="primary">
                <Icon fontSize="default" sx={{ fontSize: 40 }}>
                  person
                </Icon>
              </MDAvatar>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {/* {`${selectedResident.firstName} ${selectedResident.lastName}`} */}
                  {selectedResident.firstName} {selectedResident.lastName}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {selectedResident.PGY}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mb={3}>
            <Grid
              container
              spacing={3}
              // justifyContent="flex-start"
              // alignItems="center"
              style={{ minHeight: "100%", marginTop: "20px" }}
            >
              {pgySortedResidentCards || null}
            </Grid>
          </MDBox>
        </Card>
      )}
    </DashboardLayout>
  );
}

export default residentDetails;
