import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const fetchOverviewScreenData = createAsyncThunk(
  "screen/fetchOverviewScreenData",
  async () => {
    const response = await axiosInstance.get("/institution/screen/overview");
    return response.data;
  }
);

const institutionProfileSlice = createSlice({
  name: "overviewScreenData",
  initialState: {
    pgySortedResidents: {},
    attending: [],
    rotations: [],
    hospitals: [],
    loadingInstitution: false,
    errorInstitution: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverviewScreenData.pending, (state) => {
        state.loadingInstitution = true;
        state.errorInstitution = null;
      })
      .addCase(fetchOverviewScreenData.fulfilled, (state, action) => {
        state.loadingInstitution = false;
        const { pgySortedResidents, rotations, hospitals, attending } = action.payload;
        state.pgySortedResidents = pgySortedResidents;
        state.attending = attending;
        state.rotations = rotations;
        state.hospitals = hospitals;
      })
      .addCase(fetchOverviewScreenData.rejected, (state, action) => {
        state.loadingInstitution = false;
        state.errorInstitution = action.error.message;
      });
  },
});

export default institutionProfileSlice.reducer;
