/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AttendingListCard from "../../../customized-components/AttendingListCard/attendingListCard";

function AttendingScreen() {
  const attending = useSelector((state) => state.overviewScreenData.attending);
  const [attendingCards, setAttendingCards] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectionChange = (event, value) => {
    setSelectedValue(value);
    // console.log("Selected value:", value);
  };

  const renderList = () =>
    attending
      .filter((eachAttending) => {
        const fullName = `${eachAttending.firstName} ${eachAttending.lastName}`.toLowerCase();
        return fullName.includes(searchInput.toLowerCase());
      })
      .map((eachAttending) => (
        <Grid
          item
          xs={6}
          md={3}
          xl={2}
          key={eachAttending.id}
          //   onClick={() => {
          //     navigate(`/resident/${resident.id}`, {
          //       state: { residentData: resident },
          //     });
          //   }}
          sx={{
            // cursor: "pointer",
            minWidth: "200px",
            transition: "0.3s",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          <AttendingListCard
            key={eachAttending.id}
            icon="person"
            color="primary"
            title={`${eachAttending.firstName} ${eachAttending.lastName}`}
            value={`${eachAttending.proceduresCount} cases`}
          />
        </Grid>
      ));

  const onSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    if (attending) {
      setAttendingCards(renderList());
    }
  }, [attending, searchInput]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!attendingCards.length ? (
        <MDBox height="100vh" mx={2} mt={-3} p={2} mb={1} textAlign="center">
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={70} />
          </Grid>
        </MDBox>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="flex-start"
            my={2}
            bgcolor="#f0f2f5"
            borderRadius="4px"
            pb={4}
          >
            <TextField
              label="Search by name"
              value={searchInput}
              onChange={onSearchInputChange}
              variant="outlined"
              size="medium"
              sx={{
                minWidth: "300px",
                fontSize: "1.2rem",
                "& .MuiOutlinedInput-root": {
                  borderColor: "#5DB5FA",
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#5DB5FA",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#5DB5FA",
                },
              }}
            />
          </Box>

          <MDBox mb={3}>
            <Grid container spacing={2} bgColor="primary">
              {attendingCards || null}
            </Grid>
          </MDBox>
        </>
      )}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default AttendingScreen;
