/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { Padding } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
// import PgyInfoCard from "examples/Cards/PgyInfoCard/pgyInfoCard";
// import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import PgyInfoCard from "../../../customized-components/PgyInfoCard/pgyInfoCard";

function ResidentScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");
  const [pgySortedResidentCards, setPgySortedResidentCards] = useState([]);

  const pgySortedResidents = useSelector((state) => state.overviewScreenData.pgySortedResidents);

  const handleSelectionChange = (event, value) => {
    setSelectedValue(value);
    if (value) {
      // Navigate to a new page with the resident's ID in the URL.
      navigate(`/resident/${value.id}`);
    }
  };

  const eachCard = (resident) => (
    <Grid
      item
      // bgcolor="#fb8c00"
      xs={6}
      md={3}
      xl={2}
      key={resident.id}
      onClick={() => {
        navigate(`/resident/${resident.id}`, {
          state: { residentData: resident },
        });
      }}
      sx={{
        cursor: "pointer",
        minWidth: "200px",
        transition: "0.3s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <PgyInfoCard
        color="primary"
        icon="person"
        title={`${resident.firstName} ${resident.lastName}`}
        description="Resident"
        value={`${resident.proceduresCount} cases`}
      />
      {/* </ButtonBase> */}
    </Grid>
  );

  const renderPgyCard = (data) => data.map((eachResident) => eachCard(eachResident));

  const extractChartLabelData = () =>
    Object.entries(pgySortedResidents)
      .map(([pgy, pgyValue]) => {
        if (pgyValue.length > 0) {
          return (
            <MDBox
              // color="white"
              bgColor="dark"
              // variant="gradient"
              borderRadius="lg"
              shadow="lg"
              opacity={1}
              p={2}
              mb={4}
            >
              <Grid item xs={12} mb={0} sx={{ lineHeight: 0 }}>
                <MDTypography variant="h5" color="white">
                  {pgy}{" "}
                </MDTypography>
              </Grid>
              <Grid
                container
                spacing={2}
                // justifyContent="flex-start"
                // alignItems="center"
                style={{ minHeight: "100%", marginTop: "20px" }}
              >
                {renderPgyCard(pgyValue)}
              </Grid>
            </MDBox>
          );
        }
        return null;
      })
      .filter((item) => item !== null);

  useEffect(() => {
    if (pgySortedResidents) {
      setPgySortedResidentCards(extractChartLabelData());
    }
  }, [dispatch, pgySortedResidents]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!pgySortedResidentCards.length ? (
        <MDBox height="100vh" mx={2} mt={-3} p={2} mb={1} textAlign="center">
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={70} />
          </Grid>
        </MDBox>
      ) : (
        <MDBox my={3}>
          <MDBox mb={3}>{pgySortedResidentCards || null}</MDBox>

          {/* <MDBox minHeight="100vh" bgColor="light">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "100%", marginTop: "100px" }}
            >
              this is the dropDown dont delete =======
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  // {...defaultProps}
                  id="auto-highlight"
                  options={initialData.residentName}
                  getOptionLabel={(option) => option.name}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField {...params} label="Relect Resident" variant="standard" />
                  )}
                  onChange={handleSelectionChange}
                />
              </Grid>
            </Grid>
          </MDBox> */}
        </MDBox>
      )}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ResidentScreen;
