import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import VerticalBarChart from "../../../examples/Charts/BarCharts/VerticalBarChart";
import { fetchOverviewScreenData } from "../../../store/reducers/overviewScreenReducer";

function Overview() {
  const dispatch = useDispatch();
  const pgySortedResidents = useSelector((state) => state.overviewScreenData.pgySortedResidents);
  const rotations = useSelector((state) => state.overviewScreenData.rotations);
  const hospitals = useSelector((state) => state.overviewScreenData.hospitals);

  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState([]);
  const [rotationChart, setRotationChart] = useState(null);
  const [hospitalChart, setHospitalChart] = useState(null);

  const extractChartLabelData = (dataArray, keyX, keyY, arg1) => {
    const labels = [];
    const data = [];
    dataArray.forEach((item) => {
      if (arg1 === "hospital") {
        const shortenName = item[keyX].split(" - ")[0];
        labels.push(shortenName);
      } else {
        labels.push(item[keyX]);
      }
      data.push(item[keyY]);
    });
    return { labels, data };
  };

  const createRotationChart = (rotationData) => {
    const { labels, data } = extractChartLabelData(rotationData, "name", "proceduresCount");
    const cartDataSet = {
      labels,
      datasets: [
        {
          label: "Procedures Completed by Resident",
          color: "primary",
          data,
        },
      ],
    };

    const rotationChartCart = (
      <Grid item xs={12} md={12}>
        <VerticalBarChart
          icon={{ color: "dark", component: "medical_services" }}
          title="Case Volume by Rotaion"
          description=" "
          chart={cartDataSet}
        />
      </Grid>
    );
    setRotationChart(rotationChartCart);
  };

  const createHospitalsChart = (hospitalsData) => {
    const { labels, data } = extractChartLabelData(
      hospitalsData,
      "name",
      "proceduresCount",
      "hospital"
    );
    const chartDataSet = {
      labels,
      datasets: [
        {
          label: "Procedures Completed by Resident",
          color: "primary",
          data,
        },
      ],
    };

    const hospitalChartCart = (
      <Grid item xs={12} md={12}>
        <VerticalBarChart
          icon={{ color: "dark", component: "local_hospital" }}
          title="Case Volume by Hospital"
          description=" "
          chart={chartDataSet}
        />
      </Grid>
    );
    setHospitalChart(hospitalChartCart);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchData = async () => {
      try {
        await dispatch(fetchOverviewScreenData());
      } catch (err) {
        if (!signal.aborted) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
        }
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      const chartsData = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [pgy, pgyValue] of Object.entries(pgySortedResidents)) {
        if (pgyValue.length > 0) {
          const { labels, data } = extractChartLabelData(pgyValue, "firstName", "proceduresCount");
          chartsData.push({
            key: [pgy],
            chartTitle: [pgy.toUpperCase()],
            labels,
            datasets: [
              {
                label: "Procedures Completed by Resident",
                color: "primary",
                data,
              },
            ],
          });
        }
        const chartsComponent = chartsData.map((eachPgy, index) => (
          <Grid item xs={12} md={6} key={eachPgy.key}>
            <VerticalBarChart
              icon={{ color: "dark", component: "splitscreen" }}
              title={eachPgy.chartTitle.toString()}
              description="Cases per PGY"
              chart={eachPgy}
            />
          </Grid>
        ));

        setCharts(chartsComponent);
      }
      createRotationChart(rotations);
      createHospitalsChart(hospitals);
    }
  }, [loading, pgySortedResidents, rotations, hospitals]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox height="100vh" mx={2} mt={-3} p={2} mb={1} textAlign="center">
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={70} />
          </Grid>
        </MDBox>
      ) : (
        <MDBox my={3}>
          <MDBox mb={6}>
            <Grid container spacing={3}>
              {charts}
            </Grid>
          </MDBox>{" "}
          <MDBox mb={12}>
            <Grid container spacing={3}>
              {rotationChart}
            </Grid>
          </MDBox>
          <MDBox mb={12}>
            <Grid container spacing={3}>
              {hospitalChart}
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Overview;
