import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const clearResidentData = createAction("ResidentDetailData/clearResidentData");

export const fetchResidentDetailData = createAsyncThunk(
  "screen/fetchResidentDetailData",
  async ({ body }) => {
    const response = await axiosInstance.post("/institution/screen/residents", body);
    // console.log("in reducer:", response.data);
    return response.data;
  }
);

const ResidentDetailSlice = createSlice({
  name: "ResidentDetailData",
  initialState: {
    selectedResident: {},
    procedureScores: {},
    loadingInstitution: false,
    errorInstitution: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResidentDetailData.pending, (state) => {
        state.loadingInstitution = true;
        state.errorInstitution = null;
      })
      .addCase(fetchResidentDetailData.fulfilled, (state, action) => {
        state.loadingInstitution = false;
        const { resident, scores } = action.payload;
        state.selectedResident = resident;
        state.procedureScores = scores;
      })
      .addCase(fetchResidentDetailData.rejected, (state, action) => {
        state.loadingInstitution = false;
        state.errorInstitution = action.error.message;
      })
      .addCase(clearResidentData, (state) => {
        state.selectedResident = {};
        state.procedureScores = {};
      });
  },
});

export default ResidentDetailSlice.reducer;
