import axios from "axios";
import { Auth } from "aws-amplify";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error getting the token", error);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
