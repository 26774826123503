/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect, useRef } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React components
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import { Auth } from "aws-amplify";

// Image
import img1 from "assets/images/illustrations/1.png";
import img2 from "assets/images/illustrations/2.png";
import img3 from "assets/images/illustrations/3.png";
import img4 from "assets/images/illustrations/4.png";

import { useDispatch } from "react-redux";
import { fetchInstitutionProfile } from "../../../../store/reducers/institutionProfileReducer";

const images = [img1, img2, img3, img4];

// eslint-disable-next-line react/prop-types
function Illustration() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [randomImage, setRandomImage] = useState(null);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const mountedRef = useRef(true); // Add a new ref to track the mounted state of the component

  const onSignIn = async () => {
    setLoading(true);
    setError("");

    try {
      await Auth.signIn(username, password);
      await dispatch(fetchInstitutionProfile());
      if (mountedRef.current) {
        navigate("/overview");
      }
    } catch (err) {
      if (mountedRef.current) {
        const { code, name, message } = err;
        setError(message);
      }
    } finally {
      if (mountedRef.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
  }, []);

  useEffect(
    () => () => {
      mountedRef.current = false; // Set the ref to false when the component is unmounted
    },
    []
  );

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignInClick = async (e) => {
    e.preventDefault();
    if (username && password) {
      // await onSignIn(username, password);
      await onSignIn();
    } else {
      // show notification banner
    }
  };
  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      A simple {name} alert with{" "}
      <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
        an example link
      </MDTypography>
      . Give it a click if you like.
    </MDTypography>
  );

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={randomImage || "assets/images/illustrations/3.png"} // Use random image or fallback to the original image
    >
      {error && (
        <MDBox mb={2}>
          <MDAlert color="error" severity="error">
            {error}
          </MDAlert>
        </MDBox>
      )}

      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            value={username}
            onChange={handleUsernameChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type={showPassword ? "text" : "password"}
            label="Password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            onClick={handleSignInClick}
            variant="gradient"
            color="info"
            size="large"
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "Sign in"}
            {/* sign in */}
          </MDButton>
        </MDBox>
        {/* <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-up/cover"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox> */}
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
